button {
    color: #000;
    display: inline-block;
    font-size: 14px;
    padding: $button-padding;
    border: 1px solid #fff;
    border-radius: 100px;
    font-family: 'Moon-Light', sans-serif;
    background-image: linear-gradient(-90deg, #00FFFD 20%, #00FF4F 100%);
    z-index: 100;
    transition: $transitions;
}

button:hover {
    border: 1px solid #000;
}

.button-video {
    position: relative;
    display: block;
    margin: 0 auto;
    margin-bottom: 75px;
}

.introduction-video {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100px;
}

.results-video {
    margin-top: 75px;
}

.pum-theme-56 .pum-content + .pum-close,
.pum-theme-enterprise-blue .pum-content + .pum-close {
    //display: none;
    background-image: linear-gradient(-90deg, #00FFFD 20%, #00FF4F 100%);
    color: #000 !important;
    right: 15px !important;
    top: 15px !important;
    :hover {
        border: 1px solid #000;
    }
}

.pum-close:hover {
    border: 1px solid #000;
}