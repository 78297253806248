                        section {
                            position: relative;
                            width: 100%;
                        }
                        
                        .landing {
                            width: 100%;
                            height: 100vh;
                            //margin-bottom: 250px;
                            button {
                                position: absolute;
                                left: 50%;
                                transform: translateX(-50%);
                                bottom: 25px;
                            }
                        }
                        
                        .landing-lines-container {
                            position: absolute;
                            width: 100%;
                            height: calc(100vh - 160px);
                            margin-top: 80px;
                            overflow: hidden;
                            li {
                                position: absolute;
                            }
                            li:first-child {
                                height: 12.5%;
                            }
                            li.line-edge {
                                width: 1312px;
                                height: 128px;
                                background: url(../../dist/images/line-edge.png) no-repeat;
                                background-size: contain;
                                top: 10vh;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                            .line-flow {
                                //border-color: $color-edge;
                                width: 1308px;
                                height: 78px;
                                background: url(../../dist/images/line-flow.png) no-repeat;
                                background-size: contain;
                                top: 30vh;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                            .line-context {
                                //border-color: $color-purpose;
                                width: 1310px;
                                height: 59px;
                                background: url(../../dist/images/line-context.png) no-repeat;
                                background-size: contain;
                                top: 60vh;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                            .line-purpose {
                                //border-color: $color-context;
                                width: 1310px;
                                height: 3px;
                                background: url(../../dist/images/line-purpose.png) no-repeat;
                                background-size: contain;
                                top: 80vh;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                        
                        .landing-text {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            text-align: center;
                            h1 {
                                font-family: 'Moon-Bold', sans-serif;
                                font-size: 38px;
                            }
                            h2 {
                                font-family: 'AvenirNext-Regular', sans-serif;
                                font-size: 22px;
                                margin-top: 50px;
                                width: 100%;
                                line-height: 30px;
                            }
                        }
                        
                        .tool-container {
                            position: relative;
                            display: block;
                            width: 90%;
                            max-width: 600px;
                            min-height: 600px;
                            margin: 0 auto;
                            margin-top: 50vh;
                            padding-top: 100px;
                            padding-bottom: 1px;
                            //background: #eee;
                            h2 {
                                margin-bottom: 75px;
                                text-align: center;
                                font-family: 'Moon-Bold', sans-serif;
                                font-size: 18px;
                            }
                            p {
                                font-family: 'AvenirNext-Regular', sans-serif;
                                font-size: 16px;
                                line-height: 25px;
                                margin-bottom: 110px;
                                span {
                                    font-family: 'AvenirNext-Bold', sans-serif;
                                    font-size: 16px;
                                    line-height: 25px;
                                }
                            }
                            .tool-title {}
                        }
                        
                        .hulpvragen-container {
                            position: relative;
                            height: 88px;
                            margin: 0 auto;
                            margin-bottom: 100px;
                            text-align: center;
                            transition: $transitions;
                            //background: #ff0;
                            width: 80%;
                            span {
                                position: relative;
                                display: block;
                                font-family: 'AvenirNext-Italic', sans-serif;
                                font-style: italic;
                                font-size: 16px;
                                line-height: 25px;
                            }
                            button {
                                width: 30px;
                                height: 30px;
                                position: relative;
                                font-size: 0;
                                border: 0px;
                                background: url(../images/arrow-button.png) no-repeat;
                                background-size: contain;
                                left: 6px;
                                top: 6px;
                            }
                            button:hover {
                                background: url(../images/arrow-button-active.png) no-repeat;
                                background-size: contain;
                            }
                        }
                        
                        .conclusion {
                            min-height: 100vh;
                            .conclusion-title {
                                position: relative;
                                display: block;
                                text-align: center;
                                margin-top: 50vh;
                                padding-top: 50px;
                                h2 {
                                    font-family: 'Moon-Bold', sans-serif;
                                    font-size: 38px;
                                }
                            }
                            .conclusion-results {
                                position: relative;
                                display: block;
                                width: 90%;
                                max-width: 600px;
                                min-height: 600px;
                                margin: 0 auto;
                                padding-top: 238px;
                                padding-bottom: 100px;
                                h2 {
                                    font-family: 'Moon-Bold', sans-serif;
                                    font-size: 18px;
                                    line-height: 15px;
                                    margin-bottom: 14px;
                                }
                                p {
                                    font-family: 'AvenirNext-Regular', sans-serif;
                                    font-size: 16px;
                                    line-height: 25px;
                                    margin-bottom: 21px;
                                }
                            }
                            .mailresults {
                                width: 100%;
                                position: absolute;
                                text-align: center;
                                bottom: 25px;
                                button {
                                    position: relative;
                                    display: inline-block;
                                }
                                input {
                                    font-family: 'AvenirNext-Regular', sans-serif;
                                    position: relative;
                                    display: inline-block;
                                    color: #000;
                                    border-bottom: 1px #DfDfDf solid;
                                }
                            }
                        }
                        
                        @media screen and (max-width: 500px) {
                            .conclusion {
                                .mailresults {
                                    button {}
                                    input {
                                        margin-bottom: 25px;
                                    }
                                }
                            }
                        }
                        
                        // Icon Lines Content
                        .content-line {
                            position: absolute;
                            margin-top: 106px;
                            //border-bottom-width: 2px;
                            //border-bottom-style: solid;
                            pointer-events: none;
                        }
                        
                        .content-line-edge {
                            width: 1312px;
                            height: 128px;
                            background: url(../../dist/images/line-edge.png) no-repeat;
                            background-size: contain;
                            top: -27px;
                            right: calc(50% + 33px);
                            transform: translateY(-50%);
                        }
                        
                        .content-line-flow {
                            width: 1312px;
                            height: 128px;
                            background: url(../../dist/images/line-flow.png) no-repeat;
                            background-size: contain;
                            top: 12px;
                            right: calc(50% + 35px);
                            transform: translateY(-50%);
                        }
                        
                        .content-line-context {
                            width: 1312px;
                            height: 128px;
                            background: url(../../dist/images/line-context.png) no-repeat;
                            background-size: contain;
                            top: 35px;
                            right: calc(50% + 50px);
                            transform: translateY(-50%);
                        }
                        
                        .content-line-purpose {
                            width: 1312px;
                            height: 128px;
                            background: url(../../dist/images/line-purpose.png) no-repeat;
                            background-size: contain;
                            top: 63px;
                            right: calc(50% + 50px);
                            transform: translateY(-50%);
                        }
                        
                        // Icon Lines Results
                        .results-lines {
                            position: absolute;
                            pointer-events: none;
                        }
                        
                        .results-line-edge {}
                        
                        .results-line-flow {}
                        
                        .results-line-context {}
                        
                        .results-line-purpose {}
                        
                        // Hulpvragen
                        .typed-cursor {
                            opacity: 1;
                            -webkit-animation: blink 0.7s infinite;
                            -moz-animation: blink 0.7s infinite;
                            animation: blink 0.7s infinite;
                        }
                        
                        @keyframes blink {
                            0% {
                                opacity: 1;
                            }
                            50% {
                                opacity: 0;
                            }
                            100% {
                                opacity: 1;
                            }
                        }
                        
                        @-webkit-keyframes blink {
                            0% {
                                opacity: 1;
                            }
                            50% {
                                opacity: 0;
                            }
                            100% {
                                opacity: 1;
                            }
                        }
                        
                        @-moz-keyframes blink {
                            0% {
                                opacity: 1;
                            }
                            50% {
                                opacity: 0;
                            }
                            100% {
                                opacity: 1;
                            }
                        }
                        
                        // Contact 
                        .contact-container {
                            width: 100vw;
                            max-width: 100vw;
                            height: 100vh;
                        }
                        
                        .contact-content {
                            position: fixed;
                            width: 600px;
                            height: auto;
                            left: calc(50% - 300px);
                            top: 50%;
                            transform: translateY(-50%);
                            text-align: center;
                            font-family: 'AvenirNext-Regular', sans-serif;
                            font-size: 16px;
                            line-height: 25px;
                            a {
                                color: #000;
                                text-decoration: underline;
                            }
                            h2 {
                                font-family: 'Moon-Bold', sans-serif;
                                font-size: 38px;
                                margin-bottom: 50px;
                            }
                            p {
                                font-family: 'AvenirNext-Regular', sans-serif;
                                font-size: 22px;
                                line-height: 30px;
                                margin-bottom: 50px;
                            }
                        }
                        
                        .websiteby {
                            position: fixed;
                            right: 25px;
                            bottom: 18px;
                            font-family: 'AvenirNext-Regular', sans-serif;
                            font-size: 14px;
                            a {
                                color: #000;
                                text-decoration: underline;
                            }
                        }
                        
                        .backgroundcolor {
                            //background: #ff0;
                        }