// Glyphicons font path
$icon-font-path: "../fonts/";
// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4;
// Colors
$brand-primary: #27ae60;
$color-black: #333;
$color-flow: #00FF55;
$color-edge: #00FE8B;
$color-purpose: #00FEC4;
$color-context: #00FDFA;
// Buttons
$button-border: 1px $color-black solid;
$button-padding: 9px 24px 8px 24px;
// Transitions
$transitions: all 0.15s ease;