body {
    width: 100%;
    height: 100%;
    background: #fff;
    font-family: sans-serif;
}

.main {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

h2 {}

img {
    width: 100%;
}