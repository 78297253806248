// Search form
.search-form {
    @extend .form-inline;
}

.search-form label {
    font-weight: normal;
    @extend .form-group;
}

.search-form .search-field {
    @extend .form-control;
}

.search-form .search-submit {
    @extend .btn;
    @extend .btn-default;
}

form {
    width: 100%;
    font-family: 'AvenirNext-Regular', sans-serif;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 50px;
    //background: #FCFCFC;
    ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #000;
    }
    ::-moz-placeholder {
        /* Firefox 19+ */
        color: #000;
    }
    :-ms-input-placeholder {
        /* IE 10+ */
        color: #000;
    }
    :-moz-placeholder {
        /* Firefox 18- */
        color: #000;
    }
    label {
        margin-bottom: 8px;
        font-family: 'AvenirNext-Bold', sans-serif;
        font-size: 16px;
        line-height: 25px;
    }
    textarea {
        width: 100%;
        margin-bottom: 50px;
        max-width: 600px;
        height: auto;
        padding: 6px 0;
        font-family: 'AvenirNext-Regular', sans-serif;
        font-size: 16px;
        line-height: 36px;
        background: url(../images/Line.png) repeat-y;
        opacity: 1;
    }
    textarea:focus {
        outline: none !important;
        border: none;
    }
    input {
        display: inline-block;
        margin-bottom: 8px;
        border-bottom: 1px #DfDfDf solid;
        padding: 6px 0;
        width: 100%;
        max-width: 600px;
        font-family: 'AvenirNext-Regular', sans-serif;
        font-size: 16px;
    }
    input:last-child {
        margin-bottom: 50px;
    }
    button {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
}