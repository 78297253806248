// MENU SIDE
.nav-container {
    position: fixed;
    width: 42px;
    left: 25px;
    z-index: 990;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    ul {
        li {
            width: 60%;
            height: auto;
            border-bottom: 1px #000 solid;
            padding-top: 9px;
            margin-bottom: 9px;
            transition: $transitions;
            span {
                position: absolute;
                font-family: 'Moon-Bold', sans-serif;
                font-size: 11px;
                margin-left: 46px;
                transform: translateY(-4.5px);
                opacity: 0;
            }
        }
        li:hover {
            width: 100%;
            span {
                opacity: 0.5;
            }
        }
        li.marginsections {
            width: 40%;
        }
        li.marginsections:hover {
            width: 100%;
            span {
                opacity: 0.5;
            }
        }
        li.active {
            width: 100%;
            span {
                opacity: 1;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .nav-container {
        width: 40px;
        left: 25px;
        top: 16px;
        transform: translateY(0%);
    }
}

// MENU HAMBURGLER
.hamburgler-icon-wrapper {
    position: fixed;
    top: 25px;
    right: 30px;
    height: 26px;
    width: 21px;
    cursor: pointer;
    z-index: 999;
}

.hamburgler-icon,
.hamburgler-icon:before,
.hamburgler-icon:after {
    content: '';
    position: absolute;
    border-radius: 1em;
    height: 1px;
    width: 26px;
    background: black;
    transition: $transitions;
}

.hamburgler-icon {
    top: 0.75em
}

.hamburgler-icon:before {
    top: -0.55em
}

.hamburgler-icon:after {
    top: 0.55em
}

.hamburgler-active .hamburgler-icon {
    background: transparent;
    transform: rotate(-135deg)
}

.hamburgler-active .hamburgler-icon:before,
.hamburgler-active .hamburgler-icon:after {
    top: 0
}

.hamburgler-active .hamburgler-icon:before {
    transform: rotate(90deg)
}

.hamburgler-active .hamburgler-icon,
.hamburgler-active .hamburgler-icon:before,
.hamburgler-active .hamburgler-icon:after {
    background: white
}

.hamburgler-menu {
    transition: all 0.3s ease;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.91);
    color: white;
    text-align: center;
    font-family: 'Moon-Bold', sans-serif;
    font-size: 18px;
    color: #fff;
    z-index: 998;
    ul {
        li {
            font-family: 'Moon-Bold', sans-serif;
            font-size: 22px;
            color: #fff;
            line-height: 36px;
            z-index: 999;
            a {
                color: #fff;
                text-decoration: none;
            }
            a:hover {
                text-decoration: underline;
            }
        }
    }
}

.hamburgler-active .hamburgler-menu {
    opacity: 1;
    pointer-events: initial;
}

.hamburgler-menu-list {
    display: block;
    transition: all 0.3s ease;
    padding: 0;
    list-style-type: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.1);
    z-index: 999;
}

.hamburgler-active .hamburgler-menu-list {
    transform: translate(-50%, -50%) scale(1);
}