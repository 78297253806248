@font-face {
    font-family: 'Moon-Light';
    src: url('../fonts/Moon-Light.woff') format('woff');
    src: url('../fonts/Moon-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Moon-Bold';
    src: url('../fonts/Moon-Bold.woff') format('woff');
    src: url('../fonts/Moon-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'AvenirNext-UltraLight';
    src: url('../fonts/AvenirNext-UltraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'AvenirNext-Regular';
    src: url('../fonts/AvenirNext-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'AvenirNext-Regular';
    src: url('../fonts/AvenirNext-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'AvenirNext-Italic';
    src: url('../fonts/AvenirNext-Italic.ttf') format('truetype');
}

//@font-face {
//    font-family: 'AvenirNext-Heavy';
//    src: url('../fonts/AvenirNext-Heavy.ttf') format('truetype');
//}